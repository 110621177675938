.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.map-container {
  width: 100%;
  height: 100vh;
}


/* The main container for the start screen */
.start-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(45deg, #000000, #003366, #004d00);
  color: #fff;
  font-family: 'Arial', sans-serif;
}

/* Game title styling */
.game-title {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

/* Description text styling */
.game-description {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 2rem;
  max-width: 80%;
}

/* Stylish start button */
.start-button {
  background-color: #fff;
  color: #f77f00;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.start-button:hover {
  background-color: #f4e1d2;
}

.game-start, .game-end {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(45deg, #000000, #003366, #004d00);
  color: white;
  font-size: 1.5em;
}

button {
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 1em;
  cursor: pointer;
  border: none;
  background-color: #3498db;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #2980b9;
}
